<template>
  <div>
    <FiltersPeople
      @getList="get_people_filters"
      @resetFilters="resetFilters"
      :loaded="loaded_people"
      @setPayload="setPayload"
      :payload="payload"
    />
    <TablePeople
      @getList="get_people"
      @resetFilters="resetFilters"
      :objectList="people"
      :loaded="loaded_people"
      :status="status_people"
      @setPayload="setPayload"
      :payload="payload"
    />
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/functions/trimInput";
import FiltersPeople from "../components/people/FiltersPeople.vue";
import TablePeople from "../components/people/TablePeople.vue";
import { useApiCall } from "@/composables/apiCall";
import { API } from "@/enums/costanti";
export default defineComponent({
  name: "sanzioni",
  components: {
    TablePeople,
    FiltersPeople,
  },
  setup() {
    const payload = ref({
      id_group: 0,
      sortcolumn: "",
      sortorder: "",
      rowstoskip: 0,
      fetchrows: 25,
    });
    const setPayload = ({ name, value }) => {
      payload.value[name] = value;
    };
    const resetFilters = () => {
      payload.value = { rowstoskip: 0, fetchrows: 25 };
      get_people();
    };

    const {
      element: people,
      loaded: loaded_people,
      getElement: get_people,
      status: status_people,
    } = useApiCall(API.ANAGRAPHICS_PEOPLE_LIST, payload);
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Persone", payload);
      await get_people();
    });

    const get_people_filters = () => {
      payload.value.rowstoskip = 0;
      get_people();
    };
    return {
      get_people_filters,
      resetFilters,
      setPayload,
      get_people,
      people,
      loaded_people,
      status_people,
      trimInput,
      payload,
    };
  },
});
</script>
