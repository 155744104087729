<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="kt_modal_add_group_csv"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Importa componenti del gruppo da file .CSV
          </h5>
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="file = {}"
            id="kt_modal_add_group_csv_close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div class="card-body p-6">
            <div class="ps-5 pe-5">
              <div class="row pb-4">
                <div class="p-0">
                  <p>
                    Tramite questa funzionalità è possibile introdurre gruppi di
                    persone.<br />
                    Il file dovrà essere in formato <b>CSV (estensione .csv)</b
                    ><br />
                    Qualunque errore di congruenza dei dati trasmessi verrà
                    segnalata nella apposita area.<br />
                    Se il risultato del controllo è positivo, si potrà procedere
                    con l'importazione tramite l'apposito tasto.
                  </p>
                </div>
              </div>
              <div class="row pb-4">
                <select
                  class="form-select"
                  name="id_group"
                  @change="selected_group = $event.target.value"
                >
                  <option :value="0" selected>Gruppo</option>
                  <option
                    v-for="group in people_results"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ group.name }}
                  </option>
                </select>
              </div>
              <div class="row pb-4">
                <div class="col-md-12 ps-0">
                  <label class="fw-bold w-100 text-start">File </label>
                  <div class="d-flex">
                    <div
                      class="image-input image-input-outline me-5"
                      data-kt-image-input="true"
                      style="background-image: url(media/avatars/blank.png)"
                    >
                      <label
                        v-if="selected_group != 0"
                        class="btn btn-icon btn-active-color-primary bg-white m-0 p-0"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="Change file"
                      >
                        <i class="bi bi-upload fs-7"></i>
                        <!--begin::Inputs-->

                        <input
                          type="file"
                          name="group"
                          accept=".csv"
                          :disabled="!selected_group"
                          @change="onInputChange($event)"
                        />
                        <!--end::Inputs-->
                      </label>
                      <label
                        v-else
                        class="btn btn-icon btn-active-color-primary bg-white m-0 p-0"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="Change file"
                      >
                        <i class="bi bi-upload fs-7"></i>
                        <!--begin::Inputs-->

                        <input
                          type="file"
                          name="group"
                          accept=".csv"
                          @click="
                            $event.preventDefault();
                            alertFailed(
                              'Selezionare un gruppo prima di continuare'
                            );
                          "
                        />
                        <!--end::Inputs-->
                      </label>
                    </div>
                    <input
                      type="text"
                      class="form-control me-2 ms-5"
                      placeholder=""
                      aria-label=""
                      :value="file ? file.id : ''"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="row card shadow">
                <div
                  class="bg-light-warning py-7 px-4 rounded"
                  v-if="!file_controllato && !errori_txt"
                >
                  <div class="text-warning fw-bold fs-5 text-center">
                    Selezionare un gruppo e caricare un file per procedere con
                    il controllo
                  </div>
                </div>
                <div
                  class="bg-light-danger py-7 px-4 rounded"
                  v-else-if="file_controllato && errori_txt"
                >
                  <div
                    class="text-danger fw-bold fs-5"
                    v-html="errori_txt"
                  ></div>
                </div>
                <div class="bg-light-success py-7 px-4 rounded" v-else>
                  <div class="text-success fw-bold fs-5 text-center">
                    Nessun errore rilevato. Procedere con l'importazione
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="kt_modal_add_group_csv_close"
            @click="file = {}"
          >
            Chiudi
          </button>
          <button
            :disabled="errori_txt || !file_controllato || !selected_group"
            type="button"
            class="badge bg-light-success text-success rounded fs-6"
            @click="importaPersone"
          >
            Procedi con l'importazione
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import useFileList from "@/composables/file-list";
import { fileSender } from "@/db/dbManag";
import { API } from "@/enums/costanti";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import {
  alertSuccess,
  alertFailed,
  callFunctionAfterAlert,
} from "@/functions/swAlert";
import { useApiCall } from "@/composables/apiCall";

export default {
  name: "upload-gruppo",
  components: { Loading },
  emits: ["getList"],
  setup(props, { emit }) {
    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      //
      addFile(e.target.files, ".csv");
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    watch(
      () => file.value.file,
      (value) => {
        if (file.value.file) {
          caricaBatch();
        } else {
          setTimeout(() => {
            file_controllato.value = false;
            errori_txt.value = "";
          }, 1000);
        }
      }
    );

    const isLoading = ref(false);

    const caricaBatch = async () => {
      if (!file.value.file) {
        alertFailed(
          "Selezionare un gruppo e caricare un file per procedere con il controllo"
        );
        return;
      }
      file_controllato.value = false;
      errori_txt.value = "";
      isLoading.value = true;
      const bodyFormData = ref(new FormData());
      bodyFormData.value.append("file", file.value.file);
      bodyFormData.value.append("id_group", selected_group.value);

      await fileSender(API.ANAGRAPHICS_PEOPLE_IMPORT, bodyFormData.value).then(
        (res) => {
          file_controllato.value = true;
          isLoading.value = false;
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            errori_txt.value =
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi";
          } else {
            file.value.status = true;
            // QUI DA VEDERE COME SARA LA STRUTTURA DEL JSON
            errori_txt.value = data && data.errori ? data.errori : "";

            persone.value = data;
            data.forEach((persona, index) => {
              if (persona.errore) {
                if (!errori_txt.value) {
                  errori_txt.value = `<p class='text-center'>Si sono verificati i seguenti errori:</p><br> <ul>`;
                }
                errori_txt.value =
                  errori_txt.value +
                  `<li>${persona.cognome} ${persona.nome}: ${persona.errore}</li>`;
              }
              if (errori_txt.value && index == data.length - 1) {
                errori_txt.value = errori_txt.value + `</ul>`;
              }
            });
          }
        }
      );
    };

    const importaPersone = () => {
      callFunctionAfterAlert(
        API.ANAGRAPHICS_PEOPLE_IMPORT_ADD,
        {
          id_group: selected_group.value,
          anagrafiche: `${JSON.stringify(persone.value)}`,
        },
        `Continuando verranno importate le persone`,
        "Persone importate con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((bool) => {
        if (bool) {
          emit("getList");
          selected_group.value = 0;
          file.value = null;
          document.getElementById("kt_modal_add_group_csv_close").click();
        }
      });
    };

    const file_controllato = ref(false);
    const errori_txt = ref("");
    const persone = ref({});

    const {
      element: people,
      loaded: loaded_groups,
      getElement: get_people,
      status: status_people,
    } = useApiCall(API.GROUPS_LIST, {
      rowstoskip: 0,
      fetchrows: 100,
      sortcolumn: "",
      sortorder: "",
    });
    get_people();

    const selected_group = ref(0);
    return {
      selected_group,
      importaPersone,
      persone,
      file_controllato,
      errori_txt,
      file,
      addFile,
      removeFile,
      onInputChange,
      caricaBatch,
      isLoading,
      people,
      alertFailed,
    };
  },
  computed: {
    people_results() {
      return this.people ? this.people.results : [];
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
    .border-active
  ):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
